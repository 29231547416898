import React, { useState, useEffect } from 'react';
import { api } from '../../utils/Api';

const UserPrescription = ({ setShowPrescription, appointmentId }) => {
  const [prescriptions, setPrescriptions] = useState([]); // To hold prescription list
  const [prescriptionDetails, setPrescriptionDetails] = useState(null); // To hold prescription details
  const [errorMessage, setErrorMessage] = useState(''); // To handle errors

  // Fetch prescriptions for the patient using dynamic appointmentId
  const fetchPrescriptions = async () => {
    try {
      const requestUrl = `patients/prescriptions/appointment/${appointmentId}`;
      console.log("Fetching from URL: ", requestUrl); // Log the URL for debugging
      const response = await api.get(requestUrl);
      setPrescriptions(response.data.data);
    } catch (error) {
      console.error('Error fetching prescriptions', error);
      setErrorMessage('Failed to fetch prescriptions. Please try again.');
    }
  };

  // Function to view prescription details
  const viewPrescriptionDetails = (prescription) => {
    setPrescriptionDetails(prescription); // Store selected prescription details
  };

  // Run the fetch when the component loads
  useEffect(() => {
    fetchPrescriptions();
  }, [appointmentId]);

  return (
    <div className="sm:pl-72 fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-10">
      <div className="h-fit sm:w-[35rem] w-full max-h-[40rem] bg-white rounded overflow-y-auto">
        <button
          onClick={() => setShowPrescription(false)}
          className="mt-3 ml-3 px-3 py-1 rounded-full bg-red-100 text-red-500 hover:bg-red-200 hover:text-red-600"
        >
          X
        </button>
        <div className="flex flex-col justify-center items-center h-full p-4">
          <h3 className='text-lg font-semibold'>Prescriptions</h3>

          {/* Prescription List */}
          {prescriptions.length ? (
            <ul className="w-full text-sm text-gray-600">
              {prescriptions.map((prescription) => (
                <li
                  key={prescription.prescriptionId}
                  onClick={() => viewPrescriptionDetails(prescription)}
                  className="cursor-pointer p-2 border-b hover:bg-gray-100 flex flex-col"
                >
                  <p> Prescription ID: <span className='text-gray-900'>{prescription.prescriptionId}</span> </p>
                  <p> Appointment ID: <span className='text-gray-900'>{prescription.appointmentId}</span> </p>
                  <p> Diagnosis: <span className='text-gray-900'>{prescription.diagnosis}</span> </p>
                  <p> Prescription Date: <span className='text-gray-900'>{prescription.createdAt}</span> </p>
                  <p> Prescription Update Date: <span className='text-gray-900'>{prescription.updatedAt}</span> </p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No prescriptions available</p>
          )}

          {/* Error Message */}
          {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
        </div>
      </div>

      {/* Prescription Details Popup */}
      {prescriptionDetails && (
        <div className="fixed sm:pl-72  inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-20">
          <div className="h-[45rem] w-[40rem] bg-white rounded p-4">
            <button
              onClick={() => setPrescriptionDetails(null)}
              className="mb-3 px-3 py-1 rounded-full bg-red-100 text-red-500 hover:bg-red-200 hover:text-red-600"
            >
              Close
            </button>
            <div>
              <h2 className="text-lg font-bold mb-2">Prescription Details</h2>
              <p><strong>ID:</strong> {prescriptionDetails.prescriptionId}</p>
              <p><strong>Diagnosis:</strong> {prescriptionDetails.diagnosis}</p>
              <p><strong>Medicines:</strong></p>
              <ul>
                {prescriptionDetails.medicines.map((medicine, index) => (
                  <li key={index} className="mb-2">
                    <p><strong>Medicine Name:</strong> {medicine.medicineName}</p>
                    <p><strong>Strength:</strong> {medicine.strength}</p>
                    <p><strong>Dosage:</strong> {medicine.dosage}</p>
                    <p><strong>Treatment Duration:</strong> {medicine.treatmentDuration}</p>
                  </li>
                ))}
              </ul>
              <p><strong>Comment:</strong> {prescriptionDetails.comment}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserPrescription;
