import React from 'react';
import android from '../images/android-os.svg';
import ios from '../images/ios-logo.svg';
import logo from '../images/logo.png'

const DownloadMobileApp = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center px-6">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-8 text-center">
        <div className='w-full flex justify-center mb-8'>
         <img src={logo} alt="iOS Logo" className="w-[10rem] " />
        </div>

        <h1 className="text-2xl font-semibold text-gray-800">Download Kenecare Mobile App</h1>
        <p className="mt-4 text-gray-600">
          Get the best experience with our mobile app. Download now for iOS or Android and stay connected!
        </p>
        <div className="mt-6 space-y-4">
          <a
            href="https://apps.apple.com/gb/app/kenecare/id6479252909"
            className="flex items-center justify-center w-full bg-blue-600 text-white text-sm font-medium py-3 rounded-lg shadow hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300"
          >
            <img src={ios} alt="iOS Logo" className="w-6 h-6 mr-2" />
            Download for iOS
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.imotechsolutions.kenecare"
            className="flex items-center justify-center w-full bg-green-500 text-white text-sm font-medium py-3 rounded-lg shadow hover:bg-green-600 focus:outline-none focus:ring focus:ring-green-300"
          >
            <img src={android} alt="Android Logo" className="w-6 h-6 mr-2" />
            Download for Android
          </a>
        </div>
      </div>
    </div>
  );
};

export default DownloadMobileApp;
