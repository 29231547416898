import { Routes, Route, Navigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./index.css";
import DoctorDashboard from "./pages/doctor/DoctorDashboard";
import UserLogin from "./components/auth/UserLogin";
import UserSignUp from "./components/auth/UserSignUp";
import DoctorSignUp from "./components/auth/DoctorSignUp";
import OtpVerification from "./components/auth/OtpVerification";
import DoctorDetailForm from "./components/auth/DoctorDetailForm";
import { fetchSpecializations, fetchCities } from "./redux/actions";
import UserDetailForm from "./components/auth/UserDetailForm";
import UserMedicalDetailsForm from "./components/auth/UserMedicalDetailsForm";
import PrivateLoginRoute from "./utils/PrivateLoginRoute";
import UserDashBoard from "./pages/user/UserDashBoard";
import UserProfileDetails from "./pages/user/UserProfileDetails";
import PrivateRoute from "./utils/PrivateRoutes";
import Appointments from "./pages/user/Appointments";
import UserMedicalDocuments from "./pages/user/UserMedicalDocuments";
import PrivateDoctorRoute from "./utils/PrivateDoctorRoute";
import DoctorCouncilRegistrationData from "./components/auth/DoctorCouncilRegistrationData";
import UploadProfilePicture from "./components/auth/UploadProfilePicture.jsx";
import ViewSpecialty from "./components/userDashboard/ViewSpecialty.jsx";
import AddFamilyMember from "./components/userDashboard/settingsFolder/AddFamilyMember.jsx";

import DoctorChartPage from "./pages/chats/DoctorChartPage.jsx";
import UserChatPage from "./pages/chats/UserChatPage.jsx";

import PaymentSuccessPage from "./components/PaymentSuccessPage.jsx";
import PaymentFailurePage from "./components/PaymentFailurePage.jsx";

import Appointments_Doctor from "./pages/doctor/Appointments";
import DoctorDetailProfile from "./pages/doctor/DoctorProfilePage.jsx";
import UploadDoctorProfilePicture from "./components/auth/UploadDoctorProfilePicture.jsx";
import WalletPage from "./pages/doctor/WalletPage.jsx";
import SettingsPage from "./pages/user/SettingsPage.jsx";
import DoctorSettingsPage from "./pages/doctor/DoctorSettingsPage.jsx";
import PatentMedicalDocuments from "./pages/doctor/PatentMedicalDocuments.jsx";
import AllDoctors from "./pages/user/AllDoctors.jsx";

import ComingSoon from "./components/ComingSoon.jsx";

import QRL_DoctorDetails from "./pages/doctor/QRL_details.jsx";

import DownloadMobileApp from "./components/DownloadMobileApp.jsx";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSpecializations());
    dispatch(fetchCities());
  }, [dispatch]);

  return (
    <>
      <Routes>
        {/* PrivateLoginRoute handles the authentication check */}
        <Route element={<PrivateLoginRoute />}>
          <Route path="/" element={<UserLogin />} />
          <Route path="/userDashboard" element={<UserDashBoard />} />
        </Route>

        <Route element={<PrivateDoctorRoute />}>
          <Route path="/doctorDashboard" element={<DoctorDashboard />} />
        </Route>

        {/*  PrivateRoute handles the authentication check */}
        <Route element={<PrivateRoute />}>
          <Route path="/allDoctors" element={<AllDoctors />} />
          <Route path="/comingSoon" element={<ComingSoon />} />

          <Route
            path="/patentMedicalDocuments"
            element={<PatentMedicalDocuments />}
          />
          <Route path="/doctorSettingsPage" element={<DoctorSettingsPage />} />
          <Route path="/addFamilyMember" element={<AddFamilyMember />} />
          <Route path="/settings_page" element={<SettingsPage />} />
          <Route
            path="/viewSpecialty/:specialtyId"
            element={<ViewSpecialty />}
          />
          <Route path="/doctorChartPage" element={<DoctorChartPage />} />
          <Route path="/wallet" element={<WalletPage />} />
          <Route path="/chat" element={<UserChatPage />} />
          <Route path="/paymentFailure" element={<PaymentFailurePage />} />
          <Route path="/paymentSuccess" element={<PaymentSuccessPage />} />
          <Route
            path="/uploadDoctorProfilePicture"
            element={<UploadDoctorProfilePicture />}
          />

          <Route path="/doctorProfile" element={<DoctorDetailProfile />} />
          <Route
            path="/appointments_doctor"
            element={<Appointments_Doctor />}
          />
          <Route
            path="/doctorCouncilRegistrationData"
            element={<DoctorCouncilRegistrationData />}
          />
          <Route path="/doctorDetailForm" element={<DoctorDetailForm />} />
          <Route path="/userDetailForm" element={<UserDetailForm />} />
          <Route
            path="/userMedicalDetailsForm"
            element={<UserMedicalDetailsForm />}
          />

          <Route path="/userProfileDetails" element={<UserProfileDetails />} />
          <Route path="/appointments" element={<Appointments />} />
          <Route
            path="/userMedicalDocuments"
            element={<UserMedicalDocuments />}
          />
        </Route>

        <Route path="/download-app" element={<DownloadMobileApp />} />


        {/* Routes for user signup and doctor signup */}
        <Route path="/otpVerification" element={<OtpVerification />} />
        <Route path="/userSignUp" element={<UserSignUp />} />
        <Route path="/doctorSignUp" element={<DoctorSignUp />} />
        <Route path="/doctorDetails/:id" element={<QRL_DoctorDetails/>} />
        <Route
          path="/uploadProfilePicture"
          element={<UploadProfilePicture />}
        />
      </Routes>
    </>
  );
}

export default App;
